/*
 * @Author: qiankun
 * @LastEditors: qiankun
 */
import { defineStore } from 'pinia';

export const useWsStore = defineStore('websocket', {
    state: () => ({
        websocket: null,
    }),
    actions: {
        create(url) {
            this.websocket = new WebSocket(url)
        },
        close() {
            this.websocket.close()
            this.websocket = null
        },
    }
});